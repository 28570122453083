import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";

const IndexPage = () => (
  <React.Fragment>
    <Helmet title="Bridge World Wide">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap"
        rel="stylesheet"
      />
      <meta property="og:title" content="Bridge World Wide" />
      <meta
        property="og:description"
        content="The Bridge World Wide website..."
      />
      <meta
        property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/bridgeworldwide-dev.appspot.com/o/developmentPage.webp?alt=media&token=0a9999c4-0701-4b4f-9c9a-e3bc8fd9c56b"
      />
      <meta property="og:url" content="http://bridgeworldwide-dev.web.app" />
    </Helmet>
    <article className="container">
      <div className="main-body">
        <div className="landing-image">
          <StaticImage
            alt="Landing banner"
            src="../images/developmentPage.webp"
          />
        </div>
        <div className="landing-title">
          <h1>Welcome to our site</h1>
          <h2>Our website is under development...</h2>
          <p>
            We are deeply sorry for the inconvenience. We will be back within a
            small time in shaa Allah. If you have any queries please contact us
            on...
          </p>
          <div
            style={{
              display: "flex",
            }}
          >
            <a target="_blank" href="https://www.facebook.com/bridgeww">
              <svg
                viewBox="0 0 512 512"
                width="50px"
                style={{ marginRight: "0.5rem" }}
              >
                <path
                  d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0"
                  fill="#4a7aff"
                />
                <path
                  d="m267.234375 511.738281c136.171875-5.878906 244.765625-118.121093 244.765625-255.738281 0-.996094-.027344-1.988281-.039062-2.984375l-177.699219-177.703125-190 198.59375 105.566406 105.566406-48.675781 66.183594zm0 0"
                  fill="#0053bf"
                />
                <path
                  d="m334.261719 75.3125v57.96875s-66.554688-9.660156-66.554688 33.277344v42.9375h60.113281l-7.511718 65.480468h-52.601563v170.679688h-66.554687v-170.679688l-56.894532-1.074218v-64.40625h55.820313v-49.378906s-3.683594-73.457032 68.703125-86.949219c30.058594-5.605469 65.480469 2.144531 65.480469 2.144531zm0 0"
                  fill="#fff"
                />
                <path
                  d="m334.261719 133.28125v-57.96875s-35.421875-7.75-65.480469-2.144531c-4.695312.875-9.0625 2.007812-13.136719 3.347656v369.140625h12.0625v-170.679688h52.597657l7.515624-65.480468h-60.113281s0 0 0-42.9375 66.554688-33.277344 66.554688-33.277344zm0 0"
                  fill="#dce1eb"
                />
              </svg>
            </a>
            <a target="_blank" href="https://wa.me/8801922456808">
              <svg
                width="55px"
                viewBox="0 0 512 512"
                enableBackground="new 0 0 512 512"
                xmlSpace="preserve"
                style={{ marginRight: "0.5rem", marginLeft: "0.5rem" }}
              >
                <path
                  fill="#EDEDED"
                  d="M0,512l35.31-128C12.359,344.276,0,300.138,0,254.234C0,114.759,114.759,0,255.117,0
	S512,114.759,512,254.234S395.476,512,255.117,512c-44.138,0-86.51-14.124-124.469-35.31L0,512z"
                />
                <path
                  fill="#55CD6C"
                  d="M137.71,430.786l7.945,4.414c32.662,20.303,70.621,32.662,110.345,32.662
	c115.641,0,211.862-96.221,211.862-213.628S371.641,44.138,255.117,44.138S44.138,137.71,44.138,254.234
	c0,40.607,11.476,80.331,32.662,113.876l5.297,7.945l-20.303,74.152L137.71,430.786z"
                />
                <path
                  fill="#FEFEFE"
                  d="M187.145,135.945l-16.772-0.883c-5.297,0-10.593,1.766-14.124,5.297
	c-7.945,7.062-21.186,20.303-24.717,37.959c-6.179,26.483,3.531,58.262,26.483,90.041s67.09,82.979,144.772,105.048
	c24.717,7.062,44.138,2.648,60.028-7.062c12.359-7.945,20.303-20.303,22.952-33.545l2.648-12.359
	c0.883-3.531-0.883-7.945-4.414-9.71l-55.614-25.6c-3.531-1.766-7.945-0.883-10.593,2.648l-22.069,28.248
	c-1.766,1.766-4.414,2.648-7.062,1.766c-15.007-5.297-65.324-26.483-92.69-79.448c-0.883-2.648-0.883-5.297,0.883-7.062
	l21.186-23.834c1.766-2.648,2.648-6.179,1.766-8.828l-25.6-57.379C193.324,138.593,190.676,135.945,187.145,135.945"
                />
              </svg>
            </a>
            <a href="tel:+61 421 332 013">
              <svg
                style={{ marginLeft: "0.5rem", marginTop: "5px" }}
                width="40"
                viewBox="0 0 406 406"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M373.333 266.88C348.33 266.88 323.84 262.976 300.629 255.317C289.301 251.413 276.437 254.421 268.992 262.016L222.976 296.768C170.176 268.587 136.384 234.816 108.587 182.4L142.4 137.472C150.912 128.96 153.963 116.501 150.315 104.832C142.592 81.472 138.667 56.96 138.667 32C138.667 14.357 124.31 0 106.667 0H32C14.357 0 0 14.357 0 32C0 237.845 167.488 405.333 373.333 405.333C390.976 405.333 405.333 390.976 405.333 373.333V298.88C405.333 281.237 390.976 266.88 373.333 266.88Z"
                    fill="url(#paint0_linear)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="202.667"
                    y1="0"
                    x2="202.667"
                    y2="405.333"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#574EAB" />
                    <stop offset="0.947917" stopColor="#F23FC5" />
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect width="405.333" height="405.333" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div className="landing-image-lg">
          <div className="image-container">
            <StaticImage
              alt="Landing banner"
              src="../images/developmentPage.webp"
            />
          </div>
        </div>
        <div className="socket">
          <p>&copy; Bridge World Wide. All Rights Reserved.</p>
        </div>
      </div>
    </article>
  </React.Fragment>
);

export default IndexPage;
